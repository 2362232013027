<template>
    <div class="photo">
        <van-image
            v-for="(item, index) in picture"
            :key="index"
            width="166"
            height="126"
            class="img"
            fit='cover'
            :src="item.doman + item.picture"
            @click="preImg(index)"/>
        <van-image-preview v-model="preImgShow" :images="preImgs" @change="onChange" :start-position='preIndex'>
            <template v-slot:index>第{{ preIndex + 1 }}页</template>
        </van-image-preview>
    </div>
</template>

<script>
import { orderDetail } from '@/apis/rescue';
export default {
    data(){
        return {
            orderId: '',
            type: '',
            picture: [],
            preImgs: [],
            preImgShow: false,
            preIndex: 0
        }
    },
    created() {
        let query = this.$route.query;
        this.type = query.type;
        this.orderId = query.id;
        this.getInfo();
    },
    methods: {
        getInfo(){
            let data = {
                order_id: this.orderId
            }
            orderDetail(data).then((res)=>{
                console.log(res,'订单详情')
                if(res.data.code == 50008){
					this.$toast('登录超时！');
                    this.$router.go(-1);
					sessionStorage.removeItem('token');
					return
				}
                this.picture = res.data.data.picture;
            })
        },
        // 查看服务照片
        preImg(index){
            this.preIndex = index;
            this.preImgs = [];
            this.picture.forEach((i)=>{
                let url = i.doman + i.picture;
                this.preImgs.push(url);
            })
            this.preImgShow = true;
        },
        onChange(index) {
            this.preIndex = index;
        },
    },
}
</script>

<style lang='scss' scoped>
    .photo{
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .img{
            margin-bottom: 15px;
        }
    }
</style>
